import React, { useState, Component } from "react";
import "../css/Toggle.css";
import axios from "axios";
import {
  JSON_LINK,
  green_max,
  orange_max,
  convert_test_index,
  old_min_limit,
  loc_arr,
  dept_arr,
  loc_to_idx,
  dept_to_idx,
  total_bio_markers,
} from "../utils";
import Oldmale from "../images/oldmale.svg";
import Oldfemale from "../images/oldfemale.svg";
import Youngmale from "../images/youngmale.svg";
import Youngfemale from "../images/youngfemale.svg";
import DashCard from "./Cards.jsx";
import ReactTooltip from "react-tooltip";
import { AiFillInfoCircle } from "react-icons/ai";
import { COLORS } from "../color";

var currdata;
class Demographics extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    axios
      .get(JSON_LINK)

      .then((res) => {
        this.setState({
          data: res.data,
        });
      });
  }

  BoolToInt = (bool) => {
    if (bool === true) return 1;
    else return 0;
  };

  render() {
    const { data } = this.state;

    var professionals = 0;
    var no_tests = 0;
    var old_male_count = 0;
    var old_male_count_percent = 0;
    var old_female_count = 0;
    var old_female_count_percent = 0;
    var young_male_count = 0;
    var young_male_count_percent = 0;
    var young_female_count = 0;
    var young_female_count_percent = 0;
    var divisor = 0;

    if (this.props.location === "All") {
      data.map((l) => {
        if (this.props.dept === "All") {
          l.Dept.map((d) => {
            d.tests.map((t) => {
              professionals += t.professionals;
              no_tests += t.no_tests;
              old_male_count += t.old_male_count;
              old_male_count_percent += t.old_male_count_percent;
              old_female_count += t.old_female_count;
              old_female_count_percent += t.old_female_count_percent;
              young_male_count += t.young_male_count;
              young_male_count_percent += t.young_male_count_percent;
              young_female_count += t.young_female_count;
              young_female_count_percent += t.young_female_count_percent;
              divisor += 1;
            });
          });
        } else {
          var idx = dept_to_idx[this.props.dept];

          // l.dept.
          l.Dept[idx].tests.map((t) => {
            professionals += t.professionals;
            no_tests += t.no_tests;
            old_male_count += t.old_male_count;
            old_male_count_percent += t.old_male_count_percent;
            old_female_count += t.old_female_count;
            old_female_count_percent += t.old_female_count_percent;
            young_male_count += t.young_male_count;
            young_male_count_percent += t.young_male_count_percent;
            young_female_count += t.young_female_count;
            young_female_count_percent += t.young_female_count_percent;
            divisor += 1;
          });
        }
      });
    } else {
      if (this.props.dept === "All") {
        var idx = loc_to_idx[this.props.location];
        data[idx].Dept.map((d) => {
          d.tests.map((t) => {
            professionals += t.professionals;
            no_tests += t.no_tests;
            old_male_count += t.old_male_count;
            old_male_count_percent += t.old_male_count_percent;
            old_female_count += t.old_female_count;
            old_female_count_percent += t.old_female_count_percent;
            young_male_count += t.young_male_count;
            young_male_count_percent += t.young_male_count_percent;
            young_female_count += t.young_female_count;
            young_female_count_percent += t.young_female_count_percent;
            divisor += 1;
          });
        });
      } else {
        //select only one dept
        var idx_l = loc_to_idx[this.props.location];
        var idx_D = dept_to_idx[this.props.dept];

        data[idx_l].Dept[idx_D].tests.map((t) => {
          professionals += t.professionals;
          no_tests += t.no_tests;
          old_male_count += t.old_male_count;
          old_male_count_percent += t.old_male_count_percent;
          old_female_count += t.old_female_count;
          old_female_count_percent += t.old_female_count_percent;
          young_male_count += t.young_male_count;
          young_male_count_percent += t.young_male_count_percent;
          young_female_count += t.young_female_count;
          young_female_count_percent += t.young_female_count_percent;
          divisor += 1;
        });
      }
    }

    old_male_count_percent = Math.round(old_male_count_percent / divisor);
    old_female_count_percent = Math.round(old_female_count_percent / divisor);
    young_male_count_percent = Math.round(young_male_count_percent / divisor);
    young_female_count_percent = Math.round(
      young_female_count_percent / divisor
    );
    no_tests = Math.round(no_tests / divisor);

    console.log(no_tests);

    const denominator =
      this.BoolToInt(this.props.third) +
      this.BoolToInt(this.props.fourth) +
      this.BoolToInt(this.props.first) +
      this.BoolToInt(this.props.second);

    var include_in_card = Array.from({ length: total_bio_markers }).fill(0);
    var percent_arr = Array.from({ length: total_bio_markers }).fill(0);
    var divisor = 0;

    if (this.props.location === "All") {
      data.map((l) => {
        if (this.props.dept === "All") {
          l.Dept.map((d) => {
            d.central_data.map((t) => {
              var idx_c = t.profile_id - 1;
              percent_arr[idx_c] +=
                t.young_male_abnormal * this.BoolToInt(this.props.third) +
                t.young_female_abnormal * this.BoolToInt(this.props.fourth) +
                t.old_male_abnormal * this.BoolToInt(this.props.first) +
                t.old_female_abnormal * this.BoolToInt(this.props.second);
            });
            divisor += 1;
          });
        } else {
          var idx = dept_to_idx[this.props.dept];

          // l.dept.
          l.Dept[idx].central_data.map((t) => {
            var idx_c = t.profile_id - 1;
            percent_arr[idx_c] +=
              t.young_male_abnormal * this.BoolToInt(this.props.third) +
              t.young_female_abnormal * this.BoolToInt(this.props.fourth) +
              t.old_male_abnormal * this.BoolToInt(this.props.first) +
              t.old_female_abnormal * this.BoolToInt(this.props.second);
          });
          divisor += 1;
        }
      });
    } else {
      if (this.props.dept === "All") {
        var idx = loc_to_idx[this.props.location];
        data[idx].Dept.map((d) => {
          d.central_data.map((t) => {
            var idx_c = t.profile_id - 1;
            percent_arr[idx_c] +=
              t.young_male_abnormal * this.BoolToInt(this.props.third) +
              t.young_female_abnormal * this.BoolToInt(this.props.fourth) +
              t.old_male_abnormal * this.BoolToInt(this.props.first) +
              t.old_female_abnormal * this.BoolToInt(this.props.second);
          });
          divisor += 1;
        });
      } else {
        //select only one dept
        var idx_l = loc_to_idx[this.props.location];
        var idx_D = dept_to_idx[this.props.dept];

        data[idx_l].Dept[idx_D].central_data.map((t) => {
          var idx_c = t.profile_id - 1;
          percent_arr[idx_c] +=
            t.young_male_abnormal * this.BoolToInt(this.props.third) +
            t.young_female_abnormal * this.BoolToInt(this.props.fourth) +
            t.old_male_abnormal * this.BoolToInt(this.props.first) +
            t.old_female_abnormal * this.BoolToInt(this.props.second);
        });
        divisor += 1;
      }
    }

    for (var i = 0; i < total_bio_markers; i++) {
      percent_arr[i] = Math.floor(percent_arr[i] / (divisor * denominator));
      if (percent_arr[i] > green_max[i]) include_in_card[i] = 1;
    }

    const loc_menu_data = loc_arr.map((l) => {
      return <option value={l}>{l}</option>;
    });
    const loc_menu =
      loc_arr.length > 1 ? (
        <select
          className="custom-select"
          name="location"
          id="loc"
          onChange={this.props.handleDropDown}
          value={this.state.value}
        >
          <option value="All">All</option>
          {loc_menu_data}
        </select>
      ) : (
        <select
          className="custom-select"
          name="location"
          id="loc"
          onChange={this.props.handleDropDown}
          value={this.state.value}
          disabled
        >
          <option value="All">All</option>
          {loc_menu_data}
        </select>
      );

    const dept_menu_data = dept_arr.map((d) => {
      return <option value={d}>{d}</option>;
    });

    const dept_menu =
      dept_arr.length > 1 ? (
        <select
          className="custom-select"
          name="department"
          id="dept"
          onChange={this.props.handleDropDown}
          value={this.state.value}
        >
          <option value="All">All</option>
          {dept_menu_data}
        </select>
      ) : (
        <select
          className="custom-select"
          name="department"
          id="dept"
          onChange={this.props.handleDropDown}
          value={this.state.value}
          disabled
        >
          <option value="All">All</option>
          {dept_menu_data}
        </select>
      );

    return (
      <>
        <div className="container margin_top">
          <br />
          <br />
          <h2 className="h2 text-center mb-4">
            Company Cuts{" "}
            <AiFillInfoCircle
              data-tip="Choose the group to display health statistics"
              className="mb-1 insight-i-icon"
            />
          </h2>

          <div className="card shadow mt-2 round_test">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-lg-6 justify-content-center">
                  <div className="col-lg-11">
                    <div className="row">Location</div>
                    <div className="row">{loc_menu}</div>
                  </div>
                </div>
                <div className="col-lg-6 justify-content-center">
                  <div className="col-lg-11">
                    <div className="row">Department</div>
                    <div className="row">{dept_menu}</div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-3">
                    <label className="containers">
                      <input
                        id="button1"
                        type="checkbox"
                        // disabled
                        onChange={this.props.clickIcon}
                        checked={this.props.FirstThird}
                      />
                      <span className="checkmarks"></span>
                    </label>
                    <br />
                    <div className="row">All Male</div>
                  </div>
                  <div className="col-lg-3">
                    <label className="containers">
                      <input
                        id="button2"
                        // disabled
                        type="checkbox"
                        onChange={this.props.clickIcon}
                        checked={this.props.SecondFourth}
                      />
                      <span className="checkmarks"></span>
                    </label>
                    <br />
                    <div className="row">All Female</div>
                  </div>
                  <div className="col-lg-3">
                    <label className="containers">
                      <input
                        id="button3"
                        type="checkbox"
                        onChange={this.props.clickIcon}
                        checked={this.props.all}
                      />
                      <span className="checkmarks"></span>
                    </label>
                    <br />
                    <div className="row">Select All</div>
                  </div>
                  <div className="col-lg-4">
                    <button
                      className="btn reset-button"
                      id="button4"
                      type="checkbox"
                      onClick={this.props.clickIcon}
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div className="col-lg-3 justify-content-center paddinginter"></div>
              </div>
              {/* <div className="row text-center mb-4">
                <div className="col-lg-2">Location</div>
                <div className="col-lg-2">Dept</div>
                <div className="col-lg-2">All Male</div>
                <div className="margin-fix">All Female</div>
                <div className="col-lg-2">Select All</div>
              </div> */}

              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="row">
                    <div className="col">
                      <h6>
                        <label className="unselectable-text bolded">
                          MALES
                        </label>
                      </h6>
                      <div className="row">
                        <div className="col">
                          <h6>
                            <label className="unselectable-text bolded">
                              &#62;= {old_min_limit} yrs
                            </label>
                          </h6>
                        </div>
                        <div className="col">
                          <h6>
                            <label className="unselectable-text bolded">
                              &#60; {old_min_limit} yrs
                            </label>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <h6>
                        <label className="unselectable-text bolded">
                          FEMALES
                        </label>
                      </h6>
                      <div className="row">
                        <div className="col">
                          <h6>
                            <label className="unselectable-text bolded">
                              &#62;= {old_min_limit} yrs
                            </label>
                          </h6>
                        </div>
                        <div className="col">
                          <h6>
                            <label className="unselectable-text bolded">
                              &#60; {old_min_limit} yrs
                            </label>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    {/* old male */}
                    <div className="col demographics-icon-margin">
                      <div className="row">
                        <img className="icon-image" src={Oldmale} alt="" />
                      </div>
                      <div className="row">
                        <span>
                          Count: {old_male_count} <br />(
                          {old_male_count_percent}%){" "}
                        </span>
                      </div>

                      <div className="row">
                        <div class="round">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            disabled={this.props.disabledFirst}
                            onChange={this.props.clickIcon}
                            checked={this.props.first}
                          />
                          <label for="checkbox1"></label>
                        </div>
                      </div>
                    </div>

                    {/* young male */}

                    <div className="col demographics-icon-margin">
                      <div className="row">
                        <img className="icon-image" src={Youngmale} alt="" />
                      </div>
                      <div className="row">
                        <span>
                          Count: {young_male_count} <br /> (
                          {young_male_count_percent}%)
                        </span>
                      </div>

                      <div className="row">
                        <div class="round">
                          <input
                            type="checkbox"
                            id="checkbox3"
                            disabled={this.props.disabledThird}
                            onChange={this.props.clickIcon}
                            checked={this.props.third}
                          />
                          <label for="checkbox3"></label>
                        </div>
                      </div>
                    </div>

                    <div className="col demographics-icon-margin">
                      <div className="row">
                        <img className="icon-image" src={Oldfemale} alt="" />
                      </div>
                      <div className="row">
                        <span>
                          Count: {old_female_count} <br /> (
                          {old_female_count_percent}%)
                        </span>
                      </div>
                      <div className="row">
                        <div class="round">
                          <input
                            type="checkbox"
                            id="checkbox2"
                            disabled={this.props.disabledSecond}
                            onChange={this.props.clickIcon}
                            checked={this.props.second}
                          />
                          <label for="checkbox2"></label>
                        </div>
                      </div>
                    </div>

                    {/* Young female */}
                    <div className="col demographics-icon-margin">
                      <div className="row text-center">
                        <img class="icon-image" src={Youngfemale} alt="" />
                      </div>
                      <div className="row">
                        <span>
                          {" "}
                          Count: {young_female_count} <br /> (
                          {young_female_count_percent}%){" "}
                        </span>
                      </div>
                      <div className="row">
                        <div class="round">
                          <input
                            type="checkbox"
                            id="checkbox4"
                            disabled={this.props.disabledFourth}
                            onChange={this.props.clickIcon}
                            checked={this.props.fourth}
                          />
                          <label for="checkbox4"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-7 col-sm-11 text-left my-4">
            <DashCard
              clickCard={this.props.clickCard}
              first={this.props.first}
              second={this.props.second}
              third={this.props.third}
              fourth={this.props.fourth}
              include_in_card={include_in_card}
            />
          </div>
        </div>
        <ReactTooltip />
      </>
    );
  }
}

export default Demographics;
